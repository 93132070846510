import { IRiskItem } from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import { QuestionnaireResponse, Tools } from "resources/classes/FhirModules/Fhir";
import { AnalyzerClass } from '../AnalyzerClass';
import { NitTools } from 'resources/classes/NursitTools';
import { IQuestionnaireList, QuestionnaireService } from '../QuestionnaireService';
import { fhirEnums } from "../../classes/fhir-enums";
import { ConfigService } from "../ConfigService";
import { Epa2_3Nrs } from "./epa2_3_Subs/epa2_3-nrs";
import { PatientItem } from "../../classes/Patient/PatientItem";
import { PatientService } from "../PatientService";
import { QuestionnaireDialogContainer } from "../../elements/questionnaire/questionnaire-dialog-container";
import { RuntimeInfo } from "../../classes/RuntimeInfo";
import { BasicForm } from "../../elements/BasicForm";
import * as environment from "../../../../config/environment.json";
import { isNaN } from "lodash";
import { HttpClient } from "aurelia-http-client";
import BundleType = fhirEnums.BundleType;
import HTTPVerb = fhirEnums.HTTPVerb;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import { AnalyzerSEMPA } from "./sempa";
import { ISempaResult, ISempaRow } from "./isempa-result";

const moment = require('moment');

export class AnalyzerVHNOE extends AnalyzerSEMPA {
    ///// START IMPLEMENTATION/OVERLOADS HERE //////////////////

    _version = "VHNOE";
    description: string = "Analyzer for Volkshilfe Niederösterreich";
    needToStoreFlags: boolean = true;

    protected generateRow(row: ISempaRow, isICDs?: boolean): string {
        if (row.tag?.toUpperCase() === "FALL" && row.isRisk) {
            const route = 'fallAnalysis';
            const fallCfg = ConfigService.GetFormSettings(route);
            if (fallCfg) {
                let fallButton = this.getButtonText('fallDetailsClicked', this.i18n.tr(fallCfg.title || "sempa_fall_button_text"), route);

                window['fallDetailsClicked'] = async () => {
                    await this.createPopup(this.patient, this.patient.latestAssessment, fallCfg.questionnaireName, false);
                };

                row.button = fallButton;
            } else {
                console.warn(`No route with name: "fallAnalysis" found in the current config.\nAnalysis page will miss the Fall-Analysis-Button!`);
            }
        }

        let result = super.generateRow(row, isICDs);

        return result;
    }

    protected generateHtmlScores(values: ISempaResult): string {
        if (!values) {
            const msg = "No values provided for generting html scores";
            if (ConfigService.Debug && !ConfigService.IsTest)
                console.warn(msg);

            return `<div data-reason="${msg}"></div>`;
        }

        const result: string = `<div class="row" id="htmlScoresParent">
                    <div class="col-sm-6" id="ncsAResultTitle"><span>${values.ncsA.title}</span> <b style="color:${values.ncsA.color}">${values.ncsA.text}</b></div>
                </div>`;

        return result;
    }

    private calculatePointsVH(assessment: any, svg: SVGImageElement, targetPathId: string) {
        function normalizePercentToString(val: Number): string {
            if (isNaN(val) || !val || val <= 0) return 'pt_0_0';
            else if (val >= 80) return 'pt_80_100';
            else if (val <= 79.9 && val >= 60) return 'pt_60_80';
            else if (val <= 59.9 && val >= 40) return 'pt_40_60';
            else if (val <= 39.9 && val >= 20) return 'pt_20_40';
            else if (val <= 19.9 && val > 1) return 'pt_0_20';
        }

        let targetPoints = [];

        //#region Kommunikation, g0
        let sum = 0;
        let max = 25; // 100%
        let p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_00'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_01'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_02'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_03'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_04'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_05'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_06'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_11'));
        const sum_g0 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g0));
        //#endregion

        //#region Bewegung, g1
        sum = 0;
        max = 25; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_100'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_103'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_104'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_105'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_106'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_107') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_108') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_109') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_110') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_111'));
        const sum_g1 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g1));
        //#endregion

        //#region Vitalfunktionen, g2
        sum = 0;
        max = 22; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_200'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_201'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_202'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_203'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_204'));
        const sum_g2 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g2));
        //#endregion

        //#region Persönliche Pflege, g3
        sum = 0;
        max = 18; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_300'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_301'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_302'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_303'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_304'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_305'));
        const sum_g3 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g3));
        //#endregion

        //#region Essen und Trinken, g4
        sum = 0;
        max = 25.5; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_400'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_401'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_402'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_403'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_404'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_405'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_406') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_407') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_408') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_409'));
        const sum_g4 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g4));
        //#endregion

        //#region Ausscheiden, g5
        sum = 0;
        max = 24; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_500'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_501'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_502') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_503'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_504'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_505') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_506'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_507') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_508') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_509'));
        const sum_g5 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g5));
        //#endregion

        //#region sich kleiden, g6
        sum = 0;
        max = 9; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_600'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_601'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_602'));
        const sum_g6 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g6));
        //#endregion

        //#region Ruhen und schlafen, g7
        sum = 0;
        max = 15; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_700'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_701'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_702'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_703'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_704'));
        const sum_g7 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g7));
        //#endregion

        //#region sich beschäftigen, g8
        sum = 0;
        max = 15; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_800'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_801'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_802'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_803'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_804'));
        const sum_g8 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g8));
        //#endregion

        //#region sex, g9
        sum = 0;
        max = 9; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_900') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_901') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_902'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_903'));
        const sum_g9 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g9));
        //#endregion

        //#region Sichere Lebens- und Haushaltsführung, g10
        sum = 0;
        max = 36; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1000'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1001'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1002'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1003'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1004'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1021'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1016'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1017'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1018'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1019'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1020'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1022'));
        const sum_g10 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g10));
        //#endregion

        //#region Soziale Beziehungen und Kognition, g11
        sum = 0;
        max = 37.5; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1100'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1101'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1102'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1103'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1104'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1105'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1106'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1107'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1108'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1109'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1110'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1111') * 1.5);
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1112'));
        const sum_g11 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g11));
        //#endregion

        //#region Umgang mit existentiellen Erfahrungen, g12
        sum = 0;
        max = 15; // 100%
        p1 = max / 100; // 1%;
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1200'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1201'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1202'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1203'));
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1204'));
        const sum_g12 = sum / p1;
        targetPoints.push(normalizePercentToString(sum_g12));
        //#endregion

        this.setSvgPointsVH(targetPathId, targetPoints, svg);
    }

    private setSvgPointsVH(polyName: string, targetPoints: string[], svg: SVGImageElement) {
        const poly: SVGPathElement = svg.querySelector('#' + polyName);
        if (!poly) {
            console.warn(`Poly "${polyName}" not found!`);
            return;
        }

        let d: string = "";

        for (let i = 0; i < targetPoints.length; i++) {
            const pt0 = 'pt_0_0';
            const pointId = targetPoints[i] === pt0 ? pt0 : `${targetPoints[i]}_${i}`;
            let pointElement = <SVGEllipseElement>svg.querySelector('#' + pointId);
            if (!pointElement) {
                pointElement = <SVGEllipseElement>svg.querySelector('#' + pt0);
            }

            if (pointElement)
                d += `${i === 0 ? '' : 'L'} ${pointElement.cx.baseVal.value},${pointElement.cy.baseVal.value} `;
        }

        d = d.trim();
        if (!d) return;
        
        d = `m ${d.trim()} z`;

        poly.setAttribute('d', d);
    }

    public override async GetSPISpider(patient: PatientItem): Promise<SVGImageElement> {
        let svg: SVGImageElement = await AnalyzerSEMPA.reloadSVGImage('risk_chart_vnhoe.svg');

        function togglePath(pathId: string, show: boolean) {
            const path: SVGPathElement = svg.querySelector('#' + pathId);
            if (path) {
                path.setAttribute('display', show ? 'inline' : 'none');
                path.style.display = show ? 'inline' : 'none';
            }
        }

        const assessmentConfig = ConfigService.GetFormSettings('assessment');
        const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentConfig.questionnaireName);
        const assessments = QuestionnaireService.GetResponsesOfType(patient, questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        const assessment = assessments[assessments.length - 1];
        if (assessment) {
            if (assessments[assessments.length - 2]) {
                try {
                    const previous = assessments[assessments.length - 2];
                    if (previous) {
                        this.calculatePointsVH(previous, svg, 'pathPrevious');
                        togglePath('pathPrevious', true);
                    } else
                        togglePath('pathPrevious', false);
                } catch (e) {
                    console.warn(e.message);
                    togglePath('pathPrevious', false);
                }
            } else {
                togglePath('pathPrevious', false);
            }


            try {
                this.calculatePointsVH(assessment, svg, 'pathCurrent');
                togglePath('pathCurrent', true);
            } catch (e) {
                console.warn(e.message);
                togglePath('pathCurrent', false);
            }
        } else {
            togglePath('pathCurrent', false);
            togglePath('pathPrevious', false);
        }

        return svg;
    }
}
